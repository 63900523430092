import { Box, Typography } from "@mui/material";
import React from "react";
import "./ServicesBox.css";
import "../CSS/Background.css";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ParkIcon from "@mui/icons-material/Park";
import Button from "@mui/material/Button";

export default function ServiceButton() {
  return (
    <Box
      className="background"
      sx={{
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
        color: "text.main",
        paddingTop: "16px",
        paddingBottom: "16px",
      }}
    >
      <Box className="box-row">
        {/*-----------Delivery box start-----------*/}
        <Box
          className="ServiceBox"
          backgroundColor="secondary.main"
          boxShadow={10}
        >
          <Box className="service-header">
            <LocalShippingIcon className="header-text" />
            <Typography className="header-text">Tree Delivery</Typography>
          </Box>
          <Typography className="ServiceBody">
            If you want to enjoy the festive spirit of a real Christmas tree
            without the hassle of transporting and setting it up yourself, we
            have the perfect solution for you. Starting at $45, our delivery and
            setup service will bring your chosen tree to your door and install
            it securely in your preferred spot.
          </Typography>
        </Box>
        {/*-----------Delivery box end-----------*/}
        {/*-----------fire retardant box start-----------*/}
        <Box
          className="ServiceBox"
          backgroundColor="secondary.main"
          boxShadow={10}
        >
          <Box
            className="service-header"
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "solid",
              borderWidth: "2px",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <FireExtinguisherIcon className="header-text" />
            <Typography className="header-text"> Fire Retardant</Typography>
          </Box>
          <Typography className="ServiceBody">
            Fire-retardant-treated Christmas trees are required in public places
            like hotels, restaurants, banks, and offices. Our team applies a
            clear, non-toxic liquid treatment that dries within hours and
            creates a barrier to make it more difficult for the tree to catch
            fire. We also provide display tags with our treatment. The cost of
            our treatment is $5 per foot.
          </Typography>
        </Box>
        {/*-----------fire retardant box end-----------*/}
      </Box>
      <Box className="box-row">
        {/*-----------tree removal box start-----------*/}
        <Box
          className="ServiceBox"
          backgroundColor="secondary.main"
          boxShadow={10}
        >
          <Box
            className="service-header"
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "solid",
              borderWidth: "2px",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <DeleteIcon className="header-text" />
            <Typography className="header-text">Tree Recycle</Typography>
          </Box>
          <Typography className="ServiceBody">
            Let us take care of your post-season tree removal needs by
            transporting it to a recycling center, where it will be chipped and
            reused as mulch or firewood. Our service starts at just $45 and
            ensures your tree is disposed of in an environmentally friendly way.
            
          </Typography>
        </Box>
        {/*-----------Tree removal box end-----------*/}
        {/*-----------Phone Orders box start-----------*/}
        <Box
          className="ServiceBox"
          backgroundColor="secondary.main"
          boxShadow={10}
        >
          <Box
            className="service-header"
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "solid",
              borderWidth: "2px",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <LocalPhoneIcon className="header-text" />
            <Typography className="header-text">
              Phone and Email Orders
            </Typography>
          </Box>
          <Typography className="ServiceBody">
            If you would like to order a Christmas tree by phone or email
            instead of online, we are happy to help you with that option. Give
            us a call or send us an email and we’ll take care of everything for
            you. Please note that there is an additional fee of $15 for phone
            and email orders. This service is only available from Monday to
            Friday.
          </Typography>
        </Box>
        {/*-----------Phone Orders box end-----------*/}
      </Box>
      <Box className="box-row">
        {/*-----------Credit Card box start-----------*/}
        <Box
          className="ServiceBox"
          backgroundColor="secondary.main"
          boxShadow={10}
        >
          <Box
            className="service-header"
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "solid",
              borderWidth: "2px",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <CreditCardIcon className="header-text" />
            <Typography className="header-text">Flocking</Typography>
          </Box>
          <Typography className="ServiceBody">
            Nothing says Christmas like a flocked tree. With its snowy white
            branches, a flocked tree will transform your home into a winter
            wonderland. We only use white flocking, but you can choose from four
            different options: light, medium, heavy, or tips only. Please note
            that flocked trees require a drying period of 1-2 days depending on
            the weather
          </Typography>
        </Box>
        {/*-----------Credit Card box end-----------*/}
        {/*-----------Decoration box start-----------*/}
        <Box
          className="ServiceBox"
          backgroundColor="secondary.main"
          boxShadow={10}
        >
          <Box
            className="service-header"
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "solid",
              borderWidth: "2px",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <ParkIcon className="header-text" />
            <Typography className="header-text">Tree Decoration</Typography>
          </Box>
          <Typography className="ServiceBody">
            Let us take the stress out of decorating your Christmas tree this
            year by allowing our team to expertly decorate it for you. With our
            tree decorating services, you can sit back and relax while we
            transform your tree into a stunning holiday centerpiece that you and
            your loved ones will enjoy all season long.
          </Typography>
        </Box>
        {/*-----------Decoration box end-----------*/}
      </Box>
    </Box>
  );
}
