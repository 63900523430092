import { Height } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import Address from "../components/Location/Address";
import LocationTitle from "../components/Location/LocationTitle";
import Map from "../components/Location/Map";

export default function Location() {
  return (
    <Box
      className="lBackground"
      sx={{
        margin: "0px",
        height: "100%",
        backgroundColor: "primary.dark",
      }}
    >
      <LocationTitle />
      <Address />
      <Map />
    </Box>
  );
}
