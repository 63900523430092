import * as React from "react";
import BackgroundVideo from "../../Assets/Videos/Mountains.mp4";
import Box from "@mui/material/Box";
import "./Background.css";

export default function homeVideoBG() {
  return (
    <Box
      sx={{
        position: "relative",
        display: "Flex",
        width: "100%",
        height: "70%",
        margin: 0,
        padding: 0,
      }}
    >
      <div className="overlay"></div>
      <video src={BackgroundVideo} autoPlay loop muted />
      <div className="content">
        <h1 className="video-text">From The Mountains Of Oregon </h1>
        <h1 className="video-text">Right To Your Living Room</h1>
      </div>
    </Box>
  );
}
