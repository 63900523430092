import React from "react";
import "./QuoteForm.css";
import { TextField } from "@mui/material";

export default function QuoteForm() {
  return (
    <div className="qbackground">
      <form className="form-box">
        <p className="form-header">Request a Quote</p>
        <div className="input-container">
          <div className="text-field">
            <input type="text" id="name" required />
            <label for="text" className="form-text">
              Name or Company
            </label>
          </div>
          <div className="text-field">
            <input type="email" id="email" required />
            <label for="email" className="form-text">
              Email Address
            </label>
          </div>
          <div className="text-field">
            <input type="tel" id="phone" required />
            <label for="tel" className="form-text">
              Phone Number
            </label>
          </div>
          <div className="text-field">
            <textarea type="text" id="message" required />
            <label for="input-box" className="form-text">
              Messsage
            </label>
          </div>
          <div className="button-container">
            <a className="form-button" href="./Quote">
              Send
            </a>
          </div>
        </div>
      </form>
      <div className="extra-space"></div>
    </div>
  );
}
