import React from "react";
import { Box } from "@mui/system";
import HarvestData from "../components/Harvest/HarvestGrid";
import HarvestTitle from "../components/Harvest/HarvestTitle";

export default function Harvest() {
  return (
    <Box>
      <HarvestTitle />
      <HarvestData />
    </Box>
  );
}
