import React from "react";
import "./AboutUs.css";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import "../CSS/Background.css";

export default function AboutUs() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "8px",
      }}
    >
      <Box className="about-us-text-box">
        <Typography className="about-us-text" color="text.main">
          We have been selling Christmas trees in West Los Angeles for 44 years.
          We are one of the oldest individually owned Christmas tree lot in the
          area, with an ever increasing loyal and discriminating clientele,
          which surprises us by following us year after year of peregrinations
          through the city. We have earned our reputation with a lot of
          research, hard work, and fair prices.
        </Typography>
        <Typography className="about-us-text" color="text.main">
          For almost ten years, we walked miles and miles of Christmas tree
          farms hand picking the best trees. We have learned a lot along the
          years and always buy trees from smaller, more quality minded growers.
          Smaller and more frequent shipments guarantees that our trees are
          always fresh. We also take very good care of our trees, watering them
          every day and keeping them in water as soon as they are opened.
        </Typography>
        <Typography className="about-us-text" color="text.main">
          We are known for having a really good variety and having large trees
          on our lots. We have trees as small as 2 ft tall up to 13 ft tall. We
          carry Douglas Fir, Noble Fir, Nordman Fir, Grand Fir , Silver Tips. We also have really beautiful wreaths in six different
          sizes.
        </Typography>
      </Box>
    </Box>
  );
}
