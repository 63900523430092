import React, { useEffect } from 'react';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./SignUp.css";
import { Typography } from "@mui/material";


const SignupBar = () => {
  useEffect(() => {
    // Ensure the script is loaded only once
    if (!document.getElementById('constantContactScript')) {
      const script = document.createElement('script');
      script.id = 'constantContactScript';
      script.innerHTML = `
        var _ctct_m = "7bcd0864c43798aa0526aa2049169f74";
      `;
      document.head.appendChild(script);

      const signupScript = document.createElement('script');
      signupScript.src = '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js';
      signupScript.async = true;
      signupScript.defer = true;
      document.body.appendChild(signupScript);
    }
  }, []);

  return (
    <div className="ctct-inline-form" data-form-id="d97665d4-fc01-4462-9161-9fb40c1d0b06"></div>
  );
}

export default SignupBar;
