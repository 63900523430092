import React from "react";
import { Box } from "@mui/material";
import "../CSS/Background.css";
import HtgTreeCard from "./HtgTreecard";

export default function TreeData() {
  return (
    <Box>
      <HtgTreeCard />
    </Box>
  );
}
