import { Box, Typography } from "@mui/material";
import React from "react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./Importantinfo.css";
import "../CSS/Background.css";

export default function importantInfo() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        padding: "8px",
        flexWrap: "wrap",
      }}
    >
      {/* ---------Delivery  start----------------*/}
      <Box
        className="deliver"
        sx={{
          display: "flex",
          padding: "16px",
          backgroundColor: "secondary.main",
          boxShadow: "10",
          borderRadius: "24px",
          margin: "16px",
        }}
      >
        <Typography
          sx={{
            color: "text.main",
            fontSize: "50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <LocalShippingIcon sx={{ fontSize: "50px" }} />
          We Deliver!
        </Typography>
      </Box>
      <Box
        className="deliver"
        sx={{
          display: "flex",
          padding: "16px",
          backgroundColor: "secondary.main",
          boxShadow: "10",
          borderRadius: "24px",
          margin: "16px",
        }}
      >
        <Typography
          sx={{
            color: "text.main",
            fontSize: "30px",
            display: "flex",
            alignItems: "center",
          }}
        >
          We open November 29th!
        </Typography>
      </Box>
      {/* ---------Delivery  end----------------*/}
      {/* ---------Hours start----------------*/}
      <Box
        className="Hours"
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          backgroundColor: "secondary.main",
          boxShadow: "10",
          borderRadius: "24px",
          margin: "16px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography
            sx={{
              color: "text.main",
              display: "flex",
              alignItems: "center",
              fontSize: "30px",
            }}
          >
            <AccessTimeIcon />
            Lot hours:
          </Typography>
        </Box>
        <Typography sx={{ color: "text.main", fontSize: "20px" }}>
          Everyday: 10AM – 9PM
        </Typography>
      </Box>
      {/* ---------Hours end----------------*/}
    </Box>
  );
}
