import { Box, Typography } from "@mui/material";
import React from "react";
import "../CSS/TitleBox.css";

export default function LocationTitle() {
  return (
    <Box className="TitleBox" sx={{ backgroundColor: "secondary.main" }}>
      <Typography
        className="title-text"
        sx={{ textAlign: "center", color: "text.main" }}
      >
        Location
      </Typography>
    </Box>
  );
}
