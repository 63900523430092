import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./TreeCard.css";

import { Box } from "@mui/material";

export default function TreeCard({}) {
  return (
    <Box className="tree-card-container">
        <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"images/Noble.jpg"}
          title={"Noble Fir"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          >
            {"Noble Fir"}
          </Typography>
          <Typography variant="body" color="text.dark">
            {
              "The Noble Fir is a species of evergreen tree that is native to the Pacific Northwest region of North America. It is known for its tall and narrow shape, sturdy branches, and bluish-green needles that have a silvery-white appearance. The needles are also densely packed and have a distinct fragrance that is often described as refreshing and invigorating. The Noble Fir is a popular choice for Christmas trees and wreaths due to its attractive appearance, long needle retention, and ability to hold heavy ornaments. Additionally, the tree is known for its excellent resistance to pests and disease, making it a durable and reliable choice for holiday decorations."
            }
          </Typography>
        </CardContent>
        <CardActions className="Cardbtn">
          <Button
            size="small"
            href={"https://i.imgur.com/4OiR7Qp.jpg"}
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            className="home-grid-button"
          >
            Click to see branches
          </Button>
        </CardActions>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"images/Douglas.jpg"}
          title={"Douglas Fir"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          >
            {"Douglas Fir"}
          </Typography>
          <Typography variant="body" color="text.dark">
            {
              "The Douglas Fir is a species of evergreen tree that is native to western North America. It is known for its tall and straight trunk, conical shape, and soft, dark green needles that have a sweet fragrance. The Douglas Fir is a popular choice for Christmas trees and other decorative uses due to its attractive appearance, durability, and pleasant aroma. The tree also has a good needle retention rate and can withstand dry indoor environments, making it a practical choice for holiday decorations. Additionally, the Douglas Fir is an important timber species and provides important habitat for a variety of wildlife species in its native range."
            }
          </Typography>
        </CardContent>
        <CardActions className="Cardbtn">
          <Button
            size="small"
            href={"https://i.imgur.com/LlIDjFP.jpg"}
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            className="home-grid-button"
          >
            Click to see branches
          </Button>
        </CardActions>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"./images/Silvertip.jpg"}
          title={"Silvertip Fir"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          >
            {"Silvertip Fir"}
          </Typography>
          <Typography variant="body" color="text.dark">
            {
              "The Silvertip Fir is a species of evergreen tree that is native to the mountains of California and Oregon in the United States. It is known for its strikingly beautiful silvery-blue foliage that is soft to the touch and has a sweet, citrusy scent. The Silvertip Fir typically grows to be a tall and slender tree, with a narrow crown and upward-sweeping branches that give it a distinctive appearance. Due to its unique beauty, the Silvertip Fir is a popular choice for Christmas trees and other decorative uses."
            }
          </Typography>
        </CardContent>
        <CardActions className="Cardbtn">
          <Button
            size="small"
            href={"https://i.imgur.com/GnEzmIb.jpg"}
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            className="home-grid-button"
          >
            Click to see branches
          </Button>
        </CardActions>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"./images/Nordman.jpg"}
          title={"Nordman Fir"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          >
            {"Nordman Fir"}
          </Typography>
          <Typography variant="body" color="text.dark">
            {
              "The Nordman Fir is a species of evergreen tree that is native to the mountains of the Caucasus region in Eurasia. It is known for its symmetrical shape, full branches, and glossy, deep green needles that are soft to the touch and have a mild fragrance. The Nordman Fir is a popular choice for Christmas trees and other decorative uses because of its dense and attractive foliage, which holds its needles well and has a long shelf life. It is also a hardy and adaptable tree that can thrive in a variety of environments."
            }
          </Typography>
        </CardContent>
        <CardActions className="Cardbtn">
          <Button
            size="small"
            href={"https://i.imgur.com/fywccnV.jpg"}
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            className="home-grid-button"
          >
            Click to see branches
          </Button>
        </CardActions>
      </Card>
      
     {/*}
<Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
  <CardMedia
    sx={{ height: "400px", paddingTop: "50.25%" }}
    image={"images/Fraser.jpg"}
    title={"Fraser Fir"}
  />
  <CardContent>
    <Typography
      gutterBottom
      component="div"
      sx={{
        fontSize: "24px",
      }}
    >
      {"Fraser Fir"}
    </Typography>
    <Typography variant="body" color="text.dark">
      {
        "The Fraser Fir is a species of evergreen tree that is native to the Appalachian Mountains in the eastern United States. It is known for its conical shape, dense branches, and dark green needles that have a silvery underside. The needles are soft to the touch and have a pleasant fragrance, making it a popular choice for Christmas trees and wreaths. The Fraser Fir also has a good needle retention rate, even in dry indoor environments, which makes it a practical and durable choice for holiday decorations. Additionally, the tree is known for its resistance to pests and disease."
      }
    </Typography>
  </CardContent>
  <CardActions className="Cardbtn">
    <Button
      size="small"
      href={"https://i.imgur.com/Dot4wjJ.jpg"}
      target="_blank"
      rel="noopener noreferrer"
      color="secondary"
      className="home-grid-button"
    >
      Click to see branches
    </Button>
  </CardActions>
</Card>
    */}


      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/psKUrqw.jpg"}
          title={"Grand Fir"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          >
            {"Grand Fir"}
          </Typography>
          <Typography variant="body" color="text.dark">
            {
              "The Grand Fir is a species of evergreen tree that is native to the Pacific Northwest region of North America. It is known for its tall stature, thick and full branches, and soft, lustrous green needles that have a distinctive citrusy scent. The Grand Fir is a popular choice for Christmas trees and wreaths due to its attractive appearance, long needle retention, and pleasant aroma. The tree is also known for its fire-resistant properties, making it a safe and reliable choice for holiday decorations. In addition, the Grand Fir provides important habitat for a variety of wildlife species in its native range."
            }
          </Typography>
        </CardContent>
        <CardActions className="Cardbtn">
          <Button
            size="small"
            href={"https://i.imgur.com/mmWwALs.jpg"}
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            className="home-grid-button"
          >
            Click to see branches
          </Button>
        </CardActions>
      </Card>
      
    </Box>
  );
}
