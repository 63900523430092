import * as React from "react";
import "./InfoBar.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export default function ButtonAppBar() {
  return (
    <AppBar className="Appbar" color="secondary">
      <Toolbar className="Toolbar">
        <Typography className="PageTitle">Shawn's Christmas Trees</Typography>

        <div className="Contact-Info">
          <Button
            className="ImportantBtn"
            variant="contained"
            href={"./quote"}
            startIcon={<AttachMoneyIcon />}
            sx={{ mr: 1, display: "none" }}
          >
            Request a Quote
          </Button>
          <Button
            className="ImportantBtn"
            variant="contained"
            href={"tel:3233256314"}
            startIcon={<PhoneIcon />}
            sx={{ mr: 1 }}
          >
            Call us: 323-325-6314
          </Button>

          <Button
            className="email-button"
            variant="contained"
            href={"mailto:shawnsxmastrees@gmail.com"}
            startIcon={<EmailIcon />}
          >
            Email us: ShawnsXmasTrees@gmail.com
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}
