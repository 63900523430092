import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import "./Footer.css";
import { Box } from "@mui/material";

import Button from "@mui/material/Button";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

export default function Footer() {
  return (
    <Box className="footer" backgroundColor="secondary.main">
      <div className="left-footer">
        <Button
          className="footer-button"
          variant="contained"
          href={"tel:3233256314"}
          startIcon={<PhoneIcon />}
          sx={{ mr: 1 }}
        >
          Call us: 323-325-6314
        </Button>

        <Button
          className="footer-button"
          variant="contained"
          href={"mailto:shawnsxmastrees@gmail.com"}
          startIcon={<EmailIcon />}
        >
          ShawnsXmasTrees@gmail.com
        </Button>
      </div>
      <Box className="right-footer">
        <Typography variant="body1" align="center" color="text.main">
          Copyright &copy; 2023 Wilk Events
        </Typography>

        <Link
          href="https://www.instagram.com/shawn_christmas_trees/"
          target="_blank"
          rel="noopener"
        >
          <img
            className="socialMedia"
            src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Instagram_colored_svg_1-512.png"
            alt="Instagram"
          />
        </Link>
      </Box>
    </Box>
  );
}
