import { Box } from "@mui/material";
import React from "react";
import QuoteForm from "../components/Quote/QuoteForm";
import QuoteTitle from "../components/Quote/QuoteTitle";

export default function Quote() {
  return (
    <div>
      <QuoteForm />
    </div>
  );
}
