import * as React from "react";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import "./htg.css";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

export default function HtgTreeCard() {
  return (
    <Box className="tree-card-container">
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"images/TreeSeedlings.jpg"}
          title={"Tree Seedling"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          >
            {"Tree Seedling"}
          </Typography>
          <Typography variant="body" color="text.dark">
            {
              "A helicopter uses a winch to pick up multiple trees from the ground in the Oregon mountains."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"images/2yotrees.jpg"}
          title={"2 year old seedlings"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          >
            {"2 year old seedlings"}
          </Typography>
          <Typography variant="body" color="text.dark">
            {
              "A 2-year-old Christmas tree sapling is a young coniferous tree that has grown from a seedling and is starting to develop more defined features. At this stage, the sapling may be a few feet tall with a thicker stem and more branches than a 1-year-old seedling. The needles are also denser and slightly longer, and the shape of the tree is beginning to take form."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"images/4yotrees.jpg"}
          title={"4 year old seedlings"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          >
            {"4 year old seedlings"}
          </Typography>
          <Typography variant="body" color="text.dark">
            {
              "A 4-year-old Christmas tree sapling is a young coniferous tree that has continued to grow and mature. At this stage, the sapling may be several feet tall with a sturdy trunk and well-formed branches. The needles are longer and denser, and the shape of the tree is becoming more defined and full. A 4-year-old sapling may also start to show signs of color variation and texture in the needles."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"images/6yotrees.jpg"}
          title={"6 year old Christmas Tree"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          >
            {"6 year old Christmas Tree"}
          </Typography>
          <Typography variant="body" color="text.dark">
            {
              "A 6-year-old Christmas tree is a young coniferous tree that has continued to grow and develop more fully. At this stage, the sapling may be several feet to over six feet tall with a thick trunk and well-formed branches. The needles are longer, denser, and more vibrant in color, and the shape of the tree is full and well-defined. A 6-year-old sapling may also begin to display signs of cones, indicating that it is becoming a mature tree."
            }
          </Typography>
        </CardContent>
      </Card>
      
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"images/8yotrees.jpg"}
          title={"10 year old Christmas Tree"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          >
            {"10 year old Christmas Tree"}
          </Typography>
          <Typography variant="body" color="text.dark">
            {
              "A 10-year-old Christmas tree sapling is a mature and flourishing coniferous tree that has been growing for over a decade. At this stage, the sapling may be between eight to twelve feet tall with a thick, sturdy trunk and well-formed, evenly spaced branches that are strong enough to hold ornaments. The needles are long, dense, and richly colored, and the shape of the tree is full and symmetrical. A 10-year-old sapling will likely have produced cones and show signs of being a mature tree."
            }
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
