import React from "react";
import { Box } from "@mui/material";
import "../CSS/Background.css";
import HarvestCard from "./HarvestCard";

export default function HarvestData() {
  return (
    <Box>
      <HarvestCard />
    </Box>
  );
}
