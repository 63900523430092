import HomeVideoBG from "../components/Homepage/Background.js";
import AboutUs from "../components/Homepage/AboutUs.js";
import NewsLetter from "../components/Homepage/NewsLetter.js";
import ImportantInfo from "../components/Homepage/Importantinfo.js";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Snow from "../components/Homepage/Snow.js";
import "../components/CSS/Background.css";

export default function Notes() {
  return (
    <Box className="background" sx={{ overflow: "hidden" }}>
       

      <HomeVideoBG />
      <div id="snow"></div>
      <Snow />
      
      <ImportantInfo />
      <AboutUs />


      <NewsLetter />
    </Box>
  );
}
