import React from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "65vh",
};

const center = {
  lat: 34.020081,
  lng: -118.422935,
};

function Map() {
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
        {/* Child components, such as markers, info windows, etc. */}
        <MarkerF position={center} label={"Shawns Christmas Trees"} />
        <></>
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(Map);
