import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./NewsLetter.css";
import Signup from "./SignUp.js";

export default function newsLetter() {
  return (
    <Box className="newsletterBackground">
      {/*-------red box design below--------*/}
      <Box className="newsletter-container">
        <Typography className="newsletter-header">
          Sign up to receive a 10% discount on your next year’s Christmas trees!
        </Typography>
        <Typography className="newsletter-body">
          Don't worry about being flooded with emails from us. We only send a
          handful of messages throughout the year, typically 4 to 5, to inform
          you of our seasonal offerings such as the Pumpkin Patch and Christmas
          tree season, including opening dates and any address changes.
        </Typography>
      </Box>
      <Signup />
    </Box>
  );
}
