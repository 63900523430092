import { Box } from "@mui/material";
import React from "react";
import ServicesBox from "../components/Services/ServicesBox";
import ServiceTitle from "../components/Services/ServicesTitle";

export default function Services() {
  return (
    <Box>
      <ServiceTitle />
      <ServicesBox />
    </Box>
  );
}
