import { Box } from "@mui/material";
import React from "react";
import TypesTitle from "../components/TypesOfTrees/TypesTitle"
import TreeCard from "../components/TypesOfTrees/TreeCard";

export default function TypesOfTrees() {
  return (
    <Box>
      <TypesTitle />
      <TreeCard/>
    </Box>
  );
}
