import { Box } from "@mui/system";
import React from "react";
import TreeData from "../components/HowTheyGrow/HtgGrid";
import HtgTitle from "../components/HowTheyGrow/HtgTitle";

export default function HowTheyGrow() {
  return (
    <Box>
      <HtgTitle />
      <TreeData />
    </Box>
  );
}
