import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import "../CSS/Background.css";

export default function HarvestCard() {
  return (
    <Box className="tree-card-container">
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/I0h8GH2.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "A helicopter uses a winch to pick up multiple trees from the ground in the Oregon mountains."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/OyDnuED.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "The helicopter flies the trees to a designated area for unloading."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/zNIq41N.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "A worker on the ground disconnects the trees from the winch and prepares them for sorting."
            }
          </Typography>
        </CardContent>
      </Card>
      
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/JOXi5vP.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "A second load of trees is lifted and flown to the drop-off zone."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/KoRywbJ.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "The helicopter moves across the fields, gathering trees from different locations."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/Cj6C8Gg.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "Workers carefully carry each tree to its designated area based on size."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/GC6zYeW.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "The trees are organized into piles according to height and shape for easier processing."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/Fo8Uz6w.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "Each tree is wrapped with a baler to ensure safe and efficient shipping."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/zuJGYgL.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "Workers push each tree through the baler, preparing it for delivery to Shawn’s Christmas Trees."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/M3IA5G7.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "Meet Andy, the owner of one of Oregon’s top Christmas tree farms!"
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/tE3ECsB.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "With years of experience in the forestry industry, Andy works alongside his son, sharing his expertise and helping to grow a thriving farm for future generations."
            }
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
