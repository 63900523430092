import * as React from "react";

import Box from "@mui/material/Box";
import Logo from "./Logo.js";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import "./NavBackground.css";

export default function BoxSx() {
  return (
    <Box className="navbar">
      <Link href="./">
        <Logo />
      </Link>

      <Box
        className="nav-menu"
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        <Button className="nav-item" variant="contained" href="/">
          Home
        </Button>
        <Button className="nav-item" variant="contained" href="/Location">
          Location
        </Button>
        <Button className="nav-item" variant="contained" href="/TypesOfTrees">
          Types of Trees
        </Button>
        <Button className="nav-item" variant="contained" href="/HowTheyGrow">
          How They Grow
        </Button>
        <Button className="nav-item" variant="contained" href="/Harvest">
          Harvest Time
        </Button>
        <Button className="nav-item" variant="contained" href="/Services">
          Services
        </Button>
      </Box>

      <nav role="navigation">
        <div id="menuToggle">
          <input type="checkbox" />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            <a href="/">
              <li>Home</li>
            </a>
            <a href="./Location">
              <li>Location</li>
            </a>
            <a href="./TypesOfTrees">
              <li>Types of Trees</li>
            </a>
            <a href="./howtheygrow">
              <li>How They Grow</li>
            </a>
            <a href="./harvest">
              <li>Harvest Time</li>
            </a>
            <a href="./services">
              <li>Services</li>
            </a>
          </ul>
        </div>
      </nav>
    </Box>
  );
}

window.onload = function () {
  const menuButton = document.querySelector(".menuButton");
  const navMenu = document.querySelector(".nav-menu");

  menuButton.addEventListener("click", () => {
    menuButton.classList.toggle("active");
    navMenu.classList.toggle("active");
  });

  document.querySelectorAll("nav-item").forEach((n) =>
    n.addEventListener("click", () => {
      menuButton.classList.remove("active");
      navMenu.classList.remove("active");
    })
  );
};
